import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import api from './lib/api'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events'
Vue.prototype.$axios = api.requests

Vue.use(Vant)
Vue.use(ElementUI, {
  size:'mini' // set element-ui default size
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
