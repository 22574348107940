import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => console.log(err))
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/PCLogin',
    name: 'PCLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/PCLogin.vue')
  },
  {
    path: '/PCMain',
    name: 'PCMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/PCView/PCMain.vue'),
    children: [
      {
        path: 'Produce',
        component: () => import(/* webpackChunkName: "about" */ '../views/PCView/基本资料/品种资料.vue')
      },
      {
        path: 'Customer',
        component: () => import(/* webpackChunkName: "about" */ '../views/PCView/基本资料/客户资料.vue')
      },
      {
        path: 'Offer',
        component: () => import(/* webpackChunkName: "about" */ '../views/PCView/基本资料/供货商资料.vue')
      },
    ]
  },

  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  {
    path: '/Stock',
    name: 'stock',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Stock.vue'),
  },
  {
    path: '/FunctionSelect',
    name: 'functionSelect',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/functionSelect.vue')
  },
  {
    path: '/Houzhengjinchang',
    name: 'Houzhengjinchang',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/HouzhengJinChang.vue')
  },
  {
    path: '/Sale',
    name: 'Sale',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Sale.vue')
  },
  {
    path: '/PeitongLog',
    name: 'peitonglog',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/PeitongLog.vue')
  },
  {
    path: '/Processes',
    name: 'Processes',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Processes.vue')
  },
  {
    path: '/Pingzhong',
    name: 'pingzhong',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Pingzhong.vue')
  },
  {
    path: '/Error',
    name: 'error',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Error.vue')
  },
  {
    path: '/Detail',
    name: 'detail',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue')
  },
  {
    path: '/YingshouKuan',
    name: 'YingshouKuan',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/YingshouKuan.vue')
  },
  {
    path: '/Yingfukuan',
    name: 'Yingfukuan',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/YingfuKuan.vue')
  },
  {
    path: '/PriceQuery',
    name: 'PriceQuery',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/PriceQuery.vue')
  },
  {
    path: '/BreedInfo',
    name: 'BreedInfo',
    require,
    component: () => import(/* webpackChunkName: "about" */ '../views/Breedinfo/BreedDetail.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// GOOD
router.beforeEach((to, from, next) => {
  var token = localStorage.getItem("tel")
  if (token != null) {
    if (to.name !== 'Login'&&token.length==0) {
      next({ name: 'Login' })
    }
    else next()
  }
  else {
    next()
  }
})
export default router
