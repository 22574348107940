import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

const baseURL = 'http://www.fabricsa.com:8082/api/'
//const baseURL = 'https://localhost:7061/api/'
// 创建 axios 实例
const requests = axios.create({
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    },
    withCredentials: false,
    baseURL: baseURL, // 请求地址前缀，将自动加在 url 前面
    timeout: 100000, // 请求超时时间  
})
requests.interceptors.request.use(function (config) {  
    const tokenStr = localStorage.getItem('tel')
    if (tokenStr) {
        config.headers['tel']= tokenStr
    }
    return config
}, function (error) {
    return Promise.reject(error)
});
//响应拦截
requests.interceptors.response.use(function (response) {
    return response
}, function (err) {
    if (err.message =='timeout of 10000ms exceeded')
    {
        Vue.prototype.$toast.fail('连接超时');
    }
    
    if (err.response.status == 401 || err.response.status == 402) {
        Vue.prototype.$toast.fail('请重新登录')
        router.replace({
            path: '/login'
        })
    }
    if (err.response.status==500)
    {
        Vue.prototype.$toast.fail('查询数据发生错误');
    }
    if (err.response.status == 415) {
        Vue.prototype.$toast.fail('查询数据发生错误');
    }
        return Promise.reject(err)
})

export default {
    requests
}